import React, { useContext, useEffect, createRef, useRef, useState } from "react";
import { toast } from "react-toastify";
import { CartContext } from "@components/contexts/CartContext";
import { MdRemoveShoppingCart, MdPayment } from "react-icons/md";
import styles from "@components/sections/cesta/Styles";

const UserForm = ({ state, setState }) => {
    const refs = useRef([]);
    const { cartItems } = useContext(CartContext);
    const [checkbox, setCheckbox] = useState(false);

    // Declaramos las referencias a los inputs
    if (refs.current.length !== 12) {
        refs.current = Array(12)
            .fill()
            .map((_, i) => refs.current[i] || createRef());
    }

    // Restauramos de la storage los datos del usuario
    useEffect(() => {
        if (window?.localStorage) {
            const data = JSON.parse(window.localStorage.getItem("user"));

            if (data) {
                // Modificamos el estado con los datos recuperados de la localStorage
                setState(prevState => {
                    return {
                        ...prevState,
                        user: {
                            name: data.name,
                            lastname: data.lastname,
                            email: data.email,
                            emailRepeat: data.emailRepeat,
                            dni: data.dni,
                            dniRepeat: data.dniRepeat,
                            phone: data.phone,
                            phoneRepeat: data.phoneRepeat,
                            address: data.address,
                            city: data.city,
                            postalCode: data.postalCode,
                            province: data.province,
                        },
                    };
                });
            }
        }
    }, [setState]);

    // Si hay modificaciones en el usuario
    useEffect(() => {
        // Guardamos valores en la local Storage
        if (window?.localStorage) {
            window.localStorage.setItem("user", JSON.stringify(state.user));
        }
    }, [state.user]);

    // Cuando hay un cambio en algún input
    const onChange = ({ e, field }) => {
        e.persist();

        setState(prevState => {
            return {
                ...prevState,
                user: {
                    ...prevState.user,
                    [field]: e.target.value,
                },
            };
        });
    };

    // Envío del formulario
    const onSubmit = async e => {
        e.preventDefault();

        if (state.checkout) {
            return;
        }

        // Si no hay productos en el carrito
        if (cartItems?.length === 0) {
            // Modificamos estado con el checkout incorrecto
            setState(prevState => {
                return {
                    ...prevState,
                    checkout: false,
                };
            });

            return;
        }

        // Miramos que los datos sean correctos
        if (state.user.email !== state.user.emailRepeat) {
            refs.current[2].current.focus();

            toast.error(
                "El correo electrónico no coincide con el correo electrónico de confirmación",
                {
                    position: "bottom-center",
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 5000,
                },
            );

            return;
        }

        if (state.user.phone !== state.user.phoneRepeat) {
            refs.current[4].current.focus();

            toast.error("El teléfono no coincide con el teléfono de confirmación", {
                position: "bottom-center",
                closeOnClick: true,
                pauseOnHover: true,
                autoClose: 5000,
            });

            return;
        }

        if (state.user.dni !== state.user.dniRepeat) {
            refs.current[10].current.focus();

            toast.error("El DNI no coincide con el DNI de confirmación", {
                position: "bottom-center",
                closeOnClick: true,
                pauseOnHover: true,
                autoClose: 5000,
            });

            return;
        }

        // Modificamos estado con el checkout correcto
        setState(prevState => {
            return {
                ...prevState,
                checkout: true,
            };
        });
    };

    return (
        <section className={styles.common.container}>
            <h1 className={styles.common.title}>Datos de contacto / envío</h1>
            <div className="mt-10">
                <form className="relative" onSubmit={onSubmit}>
                    <div className="space-y-5">
                        <input
                            ref={refs.current[0]}
                            className={`${styles.common.input(state.checkout)} `}
                            type="text"
                            required={true}
                            name={"name"}
                            value={state.user.name || ""}
                            placeholder="Nombre"
                            onChange={e => onChange({ e, field: "name" })}
                            disabled={state.checkout}
                        />

                        <input
                            ref={refs.current[1]}
                            className={styles.common.input(state.checkout)}
                            type="text"
                            name={"lastname"}
                            required={true}
                            value={state.user.lastname}
                            placeholder="Apellidos"
                            onChange={e => onChange({ e, field: "lastname" })}
                            disabled={state.checkout}
                        />

                        <input
                            ref={refs.current[10]}
                            className={styles.common.input(state.checkout)}
                            type="text"
                            name={"dni"}
                            required={true}
                            value={state.user.dni}
                            placeholder="DNI / NIE / Pasaporte"
                            onChange={e => onChange({ e, field: "dni" })}
                            disabled={state.checkout}
                        />

                        <input
                            ref={refs.current[11]}
                            className={styles.common.input(state.checkout)}
                            type="text"
                            name={"dniRepeat"}
                            required={true}
                            value={state.user.dniRepeat}
                            placeholder="DNI / NIE / Pasaporte (confirmación)"
                            onChange={e => onChange({ e, field: "dniRepeat" })}
                            disabled={state.checkout}
                        />

                        <input
                            ref={refs.current[2]}
                            className={styles.common.input(state.checkout)}
                            type="email"
                            name={"email"}
                            required={true}
                            value={state.user.email}
                            placeholder="Correo electrónico de contacto"
                            onChange={e => onChange({ e, field: "email" })}
                            disabled={state.checkout}
                        />

                        <input
                            ref={refs.current[3]}
                            className={styles.common.input(state.checkout)}
                            type="email"
                            name={"emailRepeat"}
                            required={true}
                            value={state.user.emailRepeat}
                            placeholder="Correo electrónico de contacto (confirmación)"
                            onChange={e => onChange({ e, field: "emailRepeat" })}
                            disabled={state.checkout}
                        />

                        <input
                            ref={refs.current[4]}
                            className={styles.common.input(state.checkout)}
                            type="tel"
                            name={"phone"}
                            required={true}
                            value={state.user.phone}
                            placeholder="Teléfono de contacto"
                            onChange={e => onChange({ e, field: "phone" })}
                            disabled={state.checkout}
                        />

                        <input
                            ref={refs.current[5]}
                            className={styles.common.input(state.checkout)}
                            type="tel"
                            name={"phoneRepeat"}
                            required={true}
                            value={state.user.phoneRepeat}
                            placeholder="Teléfono de contacto (confirmación)"
                            onChange={e => onChange({ e, field: "phoneRepeat" })}
                            disabled={state.checkout}
                        />

                        <input
                            ref={refs.current[6]}
                            className={`${styles.common.input(state.checkout)} md:col-span-2`}
                            type="text"
                            name={"address"}
                            required={true}
                            value={state.user.address}
                            placeholder="Dirección completa (calle, número, puerta, etc)"
                            onChange={e => onChange({ e, field: "address" })}
                            disabled={state.checkout}
                        />

                        <input
                            ref={refs.current[7]}
                            className={styles.common.input(state.checkout)}
                            type="text"
                            name={"city"}
                            required={true}
                            value={state.user.city}
                            placeholder="Ciudad o población"
                            onChange={e => onChange({ e, field: "city" })}
                            disabled={state.checkout}
                        />

                        <input
                            ref={refs.current[8]}
                            className={styles.common.input(state.checkout)}
                            type="number"
                            name={"postalCode"}
                            required={true}
                            value={state.user.postalCode}
                            placeholder="Código postal"
                            onChange={e => onChange({ e, field: "postalCode" })}
                            disabled={state.checkout}
                        />

                        <input
                            ref={refs.current[9]}
                            className={`${styles.common.input(state.checkout)} md:col-span-2`}
                            type="text"
                            name={"province"}
                            required={true}
                            value={state.user.province}
                            placeholder="Provincia"
                            onChange={e => onChange({ e, field: "province" })}
                            disabled={state.checkout}
                        />

                        <div>
                            <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    name="checkbox"
                                    id="checkbox"
                                    required={true}
                                    value={checkbox}
                                    disabled={state.checkout}
                                    onChange={e => setCheckbox(e.target.checked)}
                                />

                                <span className="ml-3 text-xs text-gray-700">
                                    Acepto la{" "}
                                    <a
                                        href="/privacidad/politica-de-privacidad/"
                                        target="_blank"
                                        className="default-link"
                                    >
                                        Política de Privacidad
                                    </a>{" "}
                                    y los{" "}
                                    <a
                                        href="/privacidad/aviso-legal/"
                                        target="_blank"
                                        className="default-link"
                                    >
                                        Términos legales
                                    </a>
                                    .
                                </span>
                            </label>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                            <button
                                id={"button-vaciar-cesta"}
                                disabled={state.checkout || cartItems?.length === 0}
                                className={styles.common.blueButton(
                                    state.checkout || cartItems?.length === 0,
                                )}
                                onClick={e => {
                                    e.preventDefault();

                                    if (state.checkout) {
                                        return;
                                    }
                                    // emptyCart();
                                }}
                            >
                                <MdRemoveShoppingCart className="w-4 h-4" />
                                <span>Vaciar cesta</span>
                            </button>

                            <button
                                id="payment-button"
                                disabled={state.checkout || cartItems?.length === 0 || !checkbox}
                                className={styles.common.redButton(
                                    state.checkout || cartItems?.length === 0 || !checkbox,
                                )}
                                type="submit"
                            >
                                <MdPayment className="w-4 h-4" />
                                <span>Realizar pago</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default UserForm;

import React, { useContext, useEffect, useState } from "react";
import addToBasketSfx from "@static/sounds/addProduct.mp3";
import removeFromBasketSfx from "@static/sounds/removeProduct.mp3";
import useSound from "use-sound";
import { AiOutlineLoading } from "react-icons/ai";
import useSWR from "swr";
import Image from "@components/common/CloudinaryImage";
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io";
import axios from "axios";
import { toast } from "react-toastify";
import { CartContext } from "@components/contexts/CartContext";
import styles from "@components/sections/cesta/Styles";

const OrderSummary = ({ state, setState }) => {
    const [playAddToBasket] = useSound(addToBasketSfx, { volume: 0.05 });
    const [playRemoveFromBasket] = useSound(removeFromBasketSfx, {
        volume: 0.05,
    });
    const [loadingDiscount, setLoadingDiscount] = useState(false);
    const [discountInput, setDiscountInput] = useState("");
    const { removeProduct } = useContext(CartContext);
    const { cartItems } = useContext(CartContext);
    const { data: products } = useSWR("/.netlify/functions/products", axios.get);

    // Cada vez que haya modificaciones en el carrito, actualizamos el estado
    useEffect(() => {
        let subtotal = 0;
        let tax = 0;
        let shipping = 0;
        let discount = 0;
        let units = 0;
        let total = 0;

        // Si no hay nada sacamos todas las ofertas que había aplicadas
        if (!cartItems || cartItems?.length === 0) {
            setState(prevState => {
                return {
                    ...prevState,
                    discount: null,
                    amount: {
                        subtotal,
                        tax,
                        shipping,
                        discount,
                        units,
                        total,
                    },
                };
            });

            return;
        } else {
            // Parseamos todos los productos
            if (products?.data?.products?.length > 0) {
                for (let i = 0; i < cartItems?.length; i++) {
                    // Obtenemos ítem del carrito
                    const cartItem = cartItems[i];

                    // Buscamos el producto en la lista de productos
                    const product = products?.data?.products.find(
                        x => x.id === cartItem.id && x.stock && x.enabled,
                    );

                    // Si no existe el producto principal, buscamos en los subproductos
                    /*if (!product) {
                        for (const mainProduct of products.data.products) {
                            if (mainProduct.products?.length > 0) {
                                const subProduct = mainProduct.products.find(
                                    sub =>
                                        sub.id === cartItem.id &&
                                        sub.stock &&
                                        sub.enabled,
                                );
                                if (subProduct) {
                                    product = subProduct;
                                    break;
                                }
                            }
                        }
                    }*/

                    // Verificar si el producto encontrado tiene subproductos y si es un producto principal
                    const isParentProduct = product && product.products?.length > 0;

                    // Si es un producto principal con subproductos, eliminarlo del carrito
                    if (isParentProduct) {
                        removeProduct(cartItems[i]);
                    } else {
                        // Si es un subproducto o producto válido sin subproductos, actualizar el carrito
                        if (product) {
                            cartItems[i] = product;
                            cartItems[i].quantity = cartItem.quantity;
                        } else {
                            // Si no existe el producto o no está activo, eliminarlo del carrito
                            removeProduct(cartItems[i]);
                        }
                    }
                }
            }
        }

        // Recorremos todo el arreglo calculando la totalización por línea
        for (let item of cartItems) {
            // Obtener la tasa de IVA del producto
            const currentTax = item.tax ?? 0;

            // Calcular el precio sin IVA
            const priceWithoutTax = item.price / (1 + currentTax);

            // Calcular el impuesto por unidad
            const taxPerUnit = priceWithoutTax * currentTax;

            // Acumular el subtotal y el total de impuestos
            subtotal += priceWithoutTax * item.quantity;
            tax += taxPerUnit * item.quantity;

            // Calcular las unidades de envío
            if (item.type === "book" || item.can_be_sent) {
                units += item.quantity * (item.items || 1);
            }
        }

        // Calculamos los gastos de envío
        if (units === 1 || units === 2) {
            shipping = Number(4.49);
        } else if (units >= 3 && units <= 5) {
            shipping = Number(units * 3.0);
        } else if (units >= 6) {
            shipping = Number(units * 2.75);
        } else {
            shipping = Number(0);
        }

        // Calculamos y validamos si hay o no descuento
        if (state.discount) {
            // Si es un descuento solo aplicable a ciertos produtcos
            if (state.discount.products && state.discount.products.length > 0) {
                const isValid = cartItems
                    .map(({ id }) => {
                        return id;
                    })
                    .some(element => {
                        return state.discount.products.indexOf(element) !== -1;
                    });

                if (!isValid) {
                    discount = 0;

                    // Quitamos el descuento del estado
                    setState(prevState => {
                        return {
                            ...prevState,
                            discount: null,
                        };
                    });

                    // Se ha de avisar al usuario
                    toast.error(
                        "Este descuento no es aplicable a los ítems que hay en el carrito.",
                        {
                            position: "bottom-center",
                            closeOnClick: true,
                            pauseOnHover: true,
                            autoClose: 5000,
                        },
                    );

                    return;
                }
            }

            if (state.discount.types && state.discount.types.length > 0) {
                const isValid = cartItems
                    .map(({ type }) => {
                        return { type };
                    })
                    .some(element => {
                        return state.discount.types.indexOf(element) !== -1;
                    });

                if (!isValid) {
                    discount = 0;

                    // Quitamos el descuento del estado
                    setState(prevState => {
                        return {
                            ...prevState,
                            discount: null,
                        };
                    });

                    // Se ha de avisar al usuario
                    toast.error(
                        "Este descuento no es aplicable a los tipo de ítems que hay en el carrito.",
                        {
                            position: "bottom-center",
                            closeOnClick: true,
                            pauseOnHover: true,
                            autoClose: 5000,
                        },
                    );

                    return;
                }
            }

            // Si es un descuento de porcentaje sobre el total
            if (state.discount.percent) {
                discount = Number(subtotal * state.discount.percent || 0);
            }
            // Si es un descuento fijo
            else if (state.discount.price) {
                discount = Number(state.discount.price || 0);
            }
        }

        // Calculamos total
        total = subtotal + tax + shipping - discount;

        // Modificamos estado
        setState(prevState => {
            return {
                ...prevState,
                amount: {
                    tax,
                    subtotal,
                    shipping,
                    discount,
                    units,
                    total,
                },
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems, setState, state.discount, removeProduct /* products */]);

    const fetchDiscountData = async e => {
        e.preventDefault();

        // Si hay algún descuento lo quitamos
        if (state.discount) {
            playRemoveFromBasket();
            setState(prevState => {
                return {
                    ...prevState,
                    discount: null,
                };
            });

            return;
        }

        if (state.user.email === "") {
            playRemoveFromBasket();

            toast.error("Para aplicar un descuento debes de rellanar los datos de usuario.", {
                position: "bottom-center",
                closeOnClick: true,
                pauseOnHover: true,
                autoClose: 5000,
            });

            return;
        }

        setLoadingDiscount(true);

        try {
            const json = await axios.post(
                "/.netlify/functions/apply-discount",
                JSON.stringify({
                    email: state.user.email,
                    slug: discountInput,
                    items: cartItems?.map(i => i.id),
                }),
            );

            // Si todo ha ido bien
            if (json?.data?.success) {
                playAddToBasket();
                setDiscountInput("");

                // Modificamos el estado
                setState(prevState => {
                    return {
                        ...prevState,
                        discount: json.data.discount,
                    };
                });
            } else {
                playRemoveFromBasket();

                toast.error(json?.data?.message || "", {
                    position: "bottom-center",
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 5000,
                });
            }
        } catch (err) {
            console.log(err?.message);

            playRemoveFromBasket();

            toast.error("No se ha podido validar correctamente el cupón de descuento.", {
                position: "bottom-center",
                closeOnClick: true,
                pauseOnHover: true,
                autoClose: 5000,
            });
        }

        setLoadingDiscount(false);
    };

    return (
        <section className={styles.common.container}>
            <h1 className={styles.common.title}>Resumen del pedido</h1>

            <table className={styles.orderSummary.desktop_table}>
                <thead className={styles.orderSummary.thead}>
                    <tr>
                        <th className={styles.orderSummary.th}>Producto</th>
                        <th className={styles.orderSummary.th}>Importe</th>
                        <th className={styles.orderSummary.th}>Cantidad</th>
                        <th className={styles.orderSummary.th}>Subtotal</th>
                    </tr>
                </thead>

                <tbody>
                    {cartItems &&
                        cartItems.map(item => {
                            return (
                                <RenderItem
                                    key={item.id}
                                    item={item}
                                    playAddToBasket={playAddToBasket}
                                    playRemoveFromBasket={playRemoveFromBasket}
                                    checkout={state.checkout}
                                />
                            );
                        })}
                </tbody>

                <tfoot className={styles.orderSummary.tfoot}>
                    <tr className={`${styles.orderSummary.tfoot_tr}`}>
                        <th colSpan="3" className={`${styles.orderSummary.th} text-right`}>
                            Subtotal:
                        </th>
                        <th colSpan="1" className={`${styles.orderSummary.th}`}>
                            {state.amount.subtotal.toFixed(2)} €
                        </th>
                    </tr>

                    <tr className={`${styles.orderSummary.tfoot_tr}`}>
                        <th colSpan="3" className={`${styles.orderSummary.th} text-right`}>
                            Impuestos:
                        </th>
                        <th colSpan="1" className={`${styles.orderSummary.th}`}>
                            {state.amount.tax.toFixed(2)} €
                        </th>
                    </tr>

                    <tr className={`${styles.orderSummary.tfoot_tr} bg-white`}>
                        <th colSpan="3" className={`${styles.orderSummary.th} text-right`}>
                            Envío {state.amount.units > 0 ? `(${state.amount.units} UDS.)` : ""}:
                        </th>
                        <th colSpan="1" className={`${styles.orderSummary.th}`}>
                            {state.amount.shipping.toFixed(2)} €
                        </th>
                    </tr>

                    <tr className={`${styles.orderSummary.tfoot_tr}`}>
                        <th className={`${styles.orderSummary.th} text-left pl-5`}>
                            {state.discount && (
                                <span className="italic text-ccyan font-semibold">
                                    {state.discount?.slug} - {state.discount?.title}
                                </span>
                            )}
                        </th>
                        <th colSpan="2" className={`${styles.orderSummary.th} text-right`}>
                            Descuento:
                        </th>
                        <th
                            colSpan="1"
                            className={`${styles.orderSummary.th} ${state.amount.discount > 0 ? "text-red-500" : ""}`}
                        >
                            {state.amount.discount > 0 ? "-" : ""}
                            {state.amount.discount.toFixed(2)} €
                        </th>
                    </tr>

                    <tr className={`${styles.orderSummary.tfoot_tr} bg-white`}>
                        <th colSpan="3" className={`${styles.orderSummary.th} text-right text-lg`}>
                            Total:
                        </th>
                        <th
                            colSpan="1"
                            className={`${styles.orderSummary.th} text-lg whitespace-no-wrap`}
                        >
                            {state.amount.total.toFixed(2)} €
                        </th>
                    </tr>
                </tfoot>
            </table>

            <div className="overflow-x-scroll lg:hidden">
                <table className={styles.orderSummary.mobile_table}>
                    <thead className={styles.orderSummary.thead}>
                        <tr>
                            <th className={styles.orderSummary.th}>Producto</th>
                        </tr>
                    </thead>

                    <tbody>
                        {cartItems &&
                            cartItems.map(item => {
                                return (
                                    <RenderItem
                                        key={item.id}
                                        item={item}
                                        mobile={true}
                                        playAddToBasket={playAddToBasket}
                                        playRemoveFromBasket={playRemoveFromBasket}
                                    />
                                );
                            })}
                    </tbody>

                    <tfoot className={styles.orderSummary.tfoot}>
                        <tr className={`${styles.orderSummary.tfoot_tr} `}>
                            <th colSpan="1" className={`${styles.orderSummary.th}`}>
                                Subtotal: {Number(state.amount.subtotal).toFixed(2)} €
                            </th>
                        </tr>

                        <tr className={`${styles.orderSummary.tfoot_tr} `}>
                            <th colSpan="1" className={`${styles.orderSummary.th}`}>
                                Impuestos: {Number(state.amount.tax).toFixed(2)} €
                            </th>
                        </tr>

                        <tr className={`${styles.orderSummary.tfoot_tr} bg-white`}>
                            <th colSpan="1" className={`${styles.orderSummary.th}`}>
                                Envío {state.amount.units > 0 ? `(${state.amount.units} UDS.)` : ""}
                                : {Number(state.amount.shipping).toFixed(2)} €
                            </th>
                        </tr>

                        <tr className={`${styles.orderSummary.tfoot_tr}`}>
                            <th colSpan="1" className={`${styles.orderSummary.th}`}>
                                Descuento:{" "}
                                <span
                                    className={`${state.amount.discount > 0 ? "text-red-500" : ""}`}
                                >
                                    {state.amount.discount > 0 ? "-" : ""}
                                    {Number(state.amount.discount).toFixed(2)} €
                                </span>
                                {state.discount && (
                                    <div className="text-xs mt-1">
                                        <span className="italic text-ccyan font-semibold">
                                            {state.discount?.slug} - {state.discount?.title}
                                        </span>
                                    </div>
                                )}
                            </th>
                        </tr>

                        <tr className={`${styles.orderSummary.tfoot_tr} bg-white`}>
                            <th colSpan="1" className={`${styles.orderSummary.th} text-lg`}>
                                Total: {Number(state.amount.total).toFixed(2)} €
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div className={styles.orderSummary.tableComments}>
                <ul>
                    <li>I.V.A. incluido en todos los precios (según producto).</li>
                    <li>Gastos de envío variables según número de artículos enviables.</li>
                </ul>
            </div>

            {process.env.GATSBY_DISCOUNTS_ENABLED === "true" && (
                <div className="mt-10">
                    <h1 className={styles.common.title}>Cupón de descuento</h1>

                    <form onSubmit={fetchDiscountData}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-5">
                            <input
                                className={styles.common.input(
                                    state.discount || state.checkout || cartItems?.length === 0,
                                )}
                                type="text"
                                required={true}
                                name="discount"
                                disabled={
                                    state.discount || state.checkout || cartItems?.length === 0
                                }
                                value={discountInput}
                                placeholder="Identificador del cupón de descuento"
                                onChange={e => setDiscountInput(e.target.value.toUpperCase())}
                            />

                            {state.discount ? (
                                <button
                                    id="remove-discount"
                                    disabled={
                                        loadingDiscount || state.checkout || cartItems?.length === 0
                                    }
                                    className={`${styles.common.blueButton} ${
                                        state.checkout ? "opacity-50 cursor-not-allowed" : ""
                                    }`}
                                    type="submit"
                                >
                                    Eliminar descuento
                                </button>
                            ) : (
                                <button
                                    id="add-discount"
                                    className={`${styles.common.redButton(
                                        loadingDiscount ||
                                            state.checkout ||
                                            cartItems?.length === 0,
                                    )} flex items-center space-x-5 ${
                                        loadingDiscount ? "opacity-50 duration-300 cursor-wait" : ""
                                    } ${state.checkout ? "opacity-50 cursor-not-allowed" : ""}`}
                                    type="submit"
                                    disabled={
                                        loadingDiscount || state.checkout || cartItems?.length === 0
                                    }
                                >
                                    {loadingDiscount && (
                                        <AiOutlineLoading className="duration-300 animate-spin" />
                                    )}
                                    <span>Aplicar cupón descuento</span>
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            )}
        </section>
    );
};

const RenderItem = ({ item, mobile, playAddToBasket, playRemoveFromBasket, checkout }) => {
    const { removeProduct, addProduct } = useContext(CartContext);

    return (
        <>
            {!mobile ? (
                <tr className={`${styles.orderSummary.tbody_tr}`}>
                    <td className={`${styles.orderSummary.tbody_td}`}>
                        <div className="flex items-center space-x-3">
                            {item.images && item.images?.length > 0 && (
                                <div className="w-20 h-20 bg-gray-100 bg-opacity-50 rounded shadow-md">
                                    <Image
                                        className="object-cover w-20 h-20 rounded"
                                        alt={item.title}
                                        filename={item.images[0]}
                                    />
                                </div>
                            )}

                            <div className="">
                                <h1>{item.title || item.name}</h1>
                                <h2 className="text-xs text-ccyan">
                                    REF: {item.id} - SKU: {item.sku}
                                </h2>
                                {item.offer && (
                                    <h3 className="text-xs text-cpurple italic">
                                        {item.offer_text && (
                                            <>
                                                <span>{item.offer_text}</span>{" "}
                                            </>
                                        )}
                                        <span className="line-through font-semibold">
                                            {Number(item.offer).toFixed(2)} €
                                        </span>
                                    </h3>
                                )}
                            </div>
                        </div>
                    </td>

                    <td className={`${styles.orderSummary.tbody_td} text-center `}>
                        {Number(item.price).toFixed(2)} €
                    </td>

                    <td className={`${styles.orderSummary.tbody_td} text-center`}>
                        <div className="items-center flex justify-around space-x-2">
                            <button
                                className={`${checkout ? "cursor-not-allowed opacity-50" : ""}`}
                                title="Decrementar unidades"
                                disabled={checkout}
                                onClick={() => {
                                    if (checkout) {
                                        return;
                                    }

                                    if (item.quantity === 1) {
                                        if (
                                            !window.confirm(
                                                "¿Estás seguro en eliminar este producto?",
                                            )
                                        ) {
                                            return;
                                        } else {
                                            playRemoveFromBasket();
                                            removeProduct(item);
                                        }
                                    } else {
                                        playRemoveFromBasket();
                                        removeProduct(item);
                                    }
                                }}
                            >
                                <IoIosRemoveCircleOutline className="w-6 h-6 text-red-500" />
                            </button>

                            <span>{Number(item.quantity).toFixed(0)}</span>

                            <button
                                className={`${checkout ? "cursor-not-allowed opacity-50" : ""}`}
                                title="Incrementar unidades"
                                disabled={checkout}
                                onClick={() => {
                                    if (checkout) {
                                        return;
                                    }
                                    playAddToBasket();
                                    addProduct(item);
                                }}
                            >
                                <IoIosAddCircleOutline className="w-6 h-6 text-green-500" />
                            </button>
                        </div>
                    </td>

                    <td className={`${styles.orderSummary.tbody_td} text-center`}>
                        {Number(item.price * item.quantity).toFixed(2)} €
                    </td>
                </tr>
            ) : (
                <tr className={`${styles.orderSummary.tbody_tr}`}>
                    <td className={`${styles.orderSummary.tbody_td} space-y-3`}>
                        <div>
                            {item.images && item.images?.length > 0 && (
                                <div className="w-full h-48 bg-gray-100 bg-opacity-50 rounded shadow-md mb-5">
                                    <Image
                                        className="object-cover w-full h-48 rounded"
                                        alt={item.title}
                                        filename={item.images[0]}
                                    />
                                </div>
                            )}

                            <h1>{item.title || item.name}</h1>
                            <h2 className="text-xs text-ccyan">
                                REF: {item.id} - SKU: {item.sku}
                            </h2>
                            {item.offer && (
                                <h3 className="text-xs text-cpurple italic">
                                    <span>{item.offer_text}</span>{" "}
                                    <span className="line-through font-semibold">
                                        {Number(item.offer).toFixed(2)} €
                                    </span>
                                </h3>
                            )}
                        </div>

                        {/* Importe */}
                        <div>
                            <h1 className="uppercase font-semibold">Importe</h1>
                            <div className="mt-2">{Number(item.price).toFixed(2)} €</div>
                        </div>

                        {/* Cantidad */}
                        <div>
                            <h1 className="uppercase font-semibold">Cantidad</h1>

                            <div className="items-center flex space-x-3 mt-2">
                                <button
                                    className={`${checkout ? "cursor-not-allowed opacity-50" : ""}`}
                                    onClick={() => {
                                        if (checkout) {
                                            return;
                                        }

                                        if (item.quantity === 1) {
                                            if (
                                                !window.confirm(
                                                    "¿Estás seguro en eliminar este producto?",
                                                )
                                            ) {
                                                return;
                                            } else {
                                                playRemoveFromBasket();
                                                removeProduct(item);
                                            }
                                        } else {
                                            playRemoveFromBasket();
                                            removeProduct(item);
                                        }
                                    }}
                                >
                                    <IoIosRemoveCircleOutline className="w-6 h-6 text-red-500" />
                                </button>

                                <span>{Number(item.quantity).toFixed(0)}</span>

                                <button
                                    className={`${checkout ? "cursor-not-allowed opacity-50" : ""}`}
                                    onClick={() => {
                                        if (checkout) {
                                            return;
                                        }
                                        playAddToBasket();
                                        addProduct(item);
                                    }}
                                >
                                    <IoIosAddCircleOutline className="w-6 h-6 text-green-500" />
                                </button>
                            </div>
                        </div>

                        {/* Subtotal */}
                        <div>
                            <h1 className="uppercase font-semibold">Subtotal</h1>

                            <div className="mt-2">
                                {Number(item.price * item.quantity).toFixed(2)} €
                            </div>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
};

export default OrderSummary;
